import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/global.css'
import allRequests from '@/utils/AllRequests.js'

Vue.config.productionTip = false;
Vue.use(ElementUI, {size:'medium'});
Vue.prototype.$allRequests=allRequests;
Vue.prototype.$baseUrl=process.env.VUE_APP_BASEURL
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
