import request from "@/utils/request";
import API from "@/utils/API.js";

const allRequests = {
    //管理员登录
    adminLogin(data) {
        return request.post(`${API.adminLogin}`,data).then(res=>{
            return res
        })

    },
    //获取管理员名单
   getAllUsers(data) {
        return request.post(`${API.getAllUsers}`,data).then(res=>{
            return  res
        })
    },
    //查询管理员
    selectUserByNum(data) {
        return request.post(`${API.selectUserByNum}`,data).then(res=>{
            return  res
        })
    },
    //创建管理员
    createAdmin(data){
        return request.post(`${API.createAdmin}`,data).then(res=>{
            return  res
        })
    },
    //删除管理员
    deleteAdmin(data){
        return request.delete(`${API.deleteAdmin}`,data).then(res=>{
            return  res
        })
    },
    //重置管理员密码
    resetAdminPwd(data){
        return request.post(`${API.resetAdminPwd}`,data).then(res=>{
            return  res
        })
    },
    //修改管理员密码
    updateAdminPwd(data){
        return request.patch(`${API.updateAdminPwd}`,data).then(res=>{
            return  res
        })
    },


    //获取所有学生用户
    getAllStudents(data){
        return request.post(`${API.getAllStudents}`,data).then(res=>{
            return  res
        })
    },
    deleteStudent(data){
        return request.delete(`${API.deleteStudent}`,data).then(res=>{
            return  res
        })
    },


    //获取所有报名
    getAllSignUp(data){
        return request.post(`${API.getAllSignUp}`,data).then(res=>{
            return  res
        })
    },
    //审批报名
    judgeSignUp(data){
        return request.patch(`${API.judgeSignUp}`,data).then(res=>{
            return  res
        })
    },
    //根据活动号和状态查询报名
    getAllSignIn(data){
        return request.post(`${API.getAllSignIn}`,data).then(res=>{
            return  res
        })
    },
    //删除评论
    deleteComment(data) {
        return request.patch(`${API.deleteComment}`, data).then(res => {
            return res
        })
    },




    //获取所有活动列表
    getAllActivities(data) {
        return request.post(`${API.getAllActivities}`,data).then(res=>{
            return res
        })
    },
    //创建活动
    createActivity(data) {
        return request.post(`${API.createActivity}`,data).then(res=>{
            return res
        })
    },
    //取消活动
    cancelActivity(data){
        return request.post(`${API.cancelActivity}`,data).then(res=>{
            return res
        })
    },
    //核实活动
    verifyActivity(data){
        return request.patch(`${API.verifyActivity}`,data).then(res=>{
            return res
        })
    },


    //创建活动室
    createClassRoom(data) {
        return request.post(`${API.createClassRoom}`,data).then(res=>{
            return res
        })
    },
    //展示活动室
    getAllClassRoom(data) {
        return request.get(`${API.getAllClassRoom}`,data).then(res=>{
            return res
        })
    },
    //设置活动室密码
    setRoomPwd(data) {
        return request.patch(`${API.setRoomPwd}`,data).then(res=>{
            return res
        })
    },
    //删除活动室
    deleteClassRoom(data) {
        return request.delete(`${API.deleteClassRoom}`,data).then(res=>{
            return res
        })
    },


    //获取申请
    getAllApplication(data) {
        return request.post(`${API.getAllApplication}`,data).then(res=>{
            return res
        })
    },
    //审批申请
    judgeApplication(data) {
        return request.patch(`${API.judgeApplication}`,data).then(res=>{
            return res
        })
    },


    //获取取消申请
    getAllCancellation(data) {
        return request.post(`${API.getAllCancellation}`,data).then(res=>{
            return res
        })
    },
    //审批取消
    judgeCancellation(data) {
        return request.patch(`${API.judgeCancellation}`,data).then(res=>{
            return res
        })
    },


    //获取所有连接
    getAllLink(data) {
        return request.post(`${API.getAllLink}`,data).then(res=>{
            return res
        })
    },
    //新增连接
    addLink(data) {
        return request.post(`${API.addLink}`,data).then(res=>{
            return res
        })
    },
    //删除连接
    deleteLink(data) {
        return request.delete(`${API.deleteLink}`,data).then(res=>{
            return res
        })
    },
    //更改连接
    alterLink(data) {
        return request.patch(`${API.alterLink}`,data).then(res=>{
            return res
        })
    },
}
export default allRequests


