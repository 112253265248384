const api = {
    //登录相关
    adminLogin:'api/manager/login',
    //用户管理
    getAllUsers:'api/manager/listManager',
    selectUserByNum:'api/manager/listManager',
    createAdmin:'api/manager/create',
    deleteAdmin:'api/manager/delete',
    resetAdminPwd:'api/manager/resetPwd',
    updateAdminPwd:'api/manager/updatePwd',
    //学社用户管理
    getAllStudents:'api/student/list',
    deleteStudent:'api/student/delete',
    //活动申请管理
    getAllApplication:'api/application/manager/list',
    judgeApplication:'api/application/manager/approval',

    //取消申请管理
    getAllCancellation:'api/cancellation/list',
    judgeCancellation:'api/cancellation/cancel',

    //活动报名管理
    getAllSignUp:'api/signup/manager/listapproval',
    judgeSignUp:'api/signup/manager/approval',
    selectSignUp:'api/signup/manager/listapproval',
    //显示签到
    getAllSignIn:'api/signup/manager/listSignin',

    //删除评论
    deleteComment:'api/signup/manager/deleteComment',


    //活动管理
    getAllActivities:"api/activity/manager/list",
    createActivity:'api/activity/manager/create',
    verifyActivity:'api/activity/manager/verify',
    cancelActivity:'api/activity/manager/cancel',
    //教室管理
    createClassRoom:'api/room/add',
    deleteClassRoom:'api/room/delete',
    getAllClassRoom:'api/room/listRoomManager',
    setRoomPwd:'api/room/alter',
    //链接资源管理
    getAllLink:'api/link/list',
    addLink:'api/link/add',
    deleteLink:'api/link/delete',
    alterLink:'api/link/alter',


}

export default api;
