import axios from "axios";
import router from "@/router";
// import config from "vue/src/core/config";


//创建请求axios对象
const request = axios.create(
    {
        baseURL:"https://future.deltalpha.cn/",//后续需要改成服务器地址 ip:port
        timeout:30000
    }
)

//拦截请求
//request拦截器，在发送请求前加一些处理，(包括数据加密、加token)
request.interceptors.request.use(config=>{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = localStorage.getItem("current-user")?JSON.parse(localStorage.getItem("current-user")):null;//获取当前的user
    config.headers['Authorization']=user?.token//根据当前的user设置请求头token，若user为null则undefine
    return config;
},error => {
        console.error('request error:'+error);
        return Promise.reject(error);
})


//拦截响应
//response拦截器。在收到响应后做处理
request.interceptors.response.use(response=>{
    //this.$message.error('拦截响应')
        console.log(response)
        let res = response.data;
        if(response.status===401)
        {
            //this.$message.error('您无权访问，请先登录')
            router.push('/login')
            res={}
            res.message='您的登录信息过期，无权访问'
            res.code=1
        }
        if(typeof res ==='string'){//兼容字符串类型
            res = res?JSON.parse(res):res;
        }
    return res;

},  error => {
        //401在error里处理？
        //let status = error.response.status
        console.log(error.response)
        router.push('/login')
        console.error('request error:'+error);
        return Promise.reject(error);
    }
)

export  default request

