import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'manager',
    component: ()=>import('../views/Manager.vue'),
    redirect:'/login',//重定向 默认渲染到home页面
    children:[
      {path:'home',name:'home',component: ()=>import('../views/manager/Home.vue')},
      {path:'adminUser',name:'adminUser',component: ()=>import('../views/manager/AdminUser.vue')},
      {path:'normalUser',name:'normalUser',component: ()=>import('../views/manager/NormalUser.vue')},
      {path:'activity',name:'activity',component: ()=>import('../views/manager/Activity.vue')},
      {path:'apply',name:'apply',component: ()=>import('../views/manager/Apply.vue')},
      {path:'classRoom',name:'classRoom',component: ()=>import('../views/manager/ClassRoom.vue')},
      {path:'personalInfo',name:'personalInfo',component: ()=>import('../views/manager/PersonalInfo.vue')},
      {path:'cancellation',name:'cancellation',component: ()=>import('../views/manager/Cancellation')},
      {path:'signUp',name:'signUp',component: ()=>import('../views/manager/ActivitySignUp')},
      {path:'link',name:'link',component: ()=>import('../views/manager/LinkResource')},
      {path:'403',name:'auth',component: ()=>import('../views/manager/Auth.vue')}
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/Login.vue')
  },
  // {path:'*',name:'404',component: ()=>import('../views/manager/404.vue')}


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //to 到达的路由信息
  //from来源信息
  //next跳转函数
  let adminPaths = ['/adminUser']
  let currenUser = JSON.parse(localStorage.getItem('current-user')||'{}')
  if(currenUser.auth!=='超级管理员'&& adminPaths.includes(to.path)){//普通管理员访问超级管理员权限路径，拦截
    next('/auth')
  }
  else{
    next()
  }

})

export default router
